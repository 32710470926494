import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'

import SEO from '../components/seo'
import PressReleaseItem from '../components/press-release-item'

const PressReleaseLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const PressReleasePage = props => (
  <StaticQuery
    query={graphql`
      query GetAllPressReleases {
        allContentfulPressRelease(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              metaDescription
              publishDate(formatString: "MMMM Do, YYYY")
              tags
              author {
                name
              }
              heroImage {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Layout pageProps={props}>
            <SEO
              title={
                'US Quality Construction Press Releases'
              }
              description={
                'Stay updated with the latest press releases, news and announcements from US Quality Construction.'
              }
            />
            <Heading>
              <h1>US Quality Construction Press Releases</h1>
              <p>
                Stay updated with the latest news and announcements from US Quality Construction.
              </p>
            </Heading>
            <PressReleaseLayoutContainer className="container">
              {data.allContentfulPressRelease.edges.map((item, i) => (
                <PressReleaseItem
                  key={i}
                  title={item.node.title}
                  slug={item.node.slug}
                  image={item.node.heroImage}
                  publishDate={item.node.publishDate}
                  author={item.node.author.name}
                  excerpt={item.node.metaDescription}
                />
              ))}
            </PressReleaseLayoutContainer>
          </Layout>
        </>
      )
    }}
  />
)

export default PressReleasePage
