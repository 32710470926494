import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../components/button'

export const ItemContainer = styled.div`
  flex: 0 0 33%;
  padding: 1%;
  
  @media (max-width: 767px) {
    flex: 0 0 50%;
  }
  
  @media (max-width: 585px) {
    flex: 0 0 100%;
  }
  
  h2 {
    font-size: calc(0.35vw + 0.9em);
    font-weight: 600;
    
    @media (max-width: 585px) {
      font-size: calc(0.35vw + 1.3em);
    }
  }
  
  p {
    color: #666;
  }
  
  .innerpadding {
    padding: 1em;
  }
`

export const PublishDate = styled.p`
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 0.75em;
  letter-spacing: 0.25px;
  font-weight: 600;
`

export const Heading = styled.h2`
  margin: 0;
  margin-bottom: 0.25em;
`

export const Excerpt = styled.p`
  font-size: 0.95em;
`

const PressReleaseItem = ({
  title,
  slug,
  image,
  publishDate,
  author,
  excerpt,
}) => {
  return (
    <ItemContainer>
      <div
        className={'shadow-md border-radius bg-white'}
        style={{ backgroundColor: 'white' }}
      >
        <GatsbyImage
          image={getImage(image)}
          style={{ height: '220px' }}
          alt={title}
        />
        <div className={'innerpadding'}>
          <PublishDate>
            {publishDate} by {author}
          </PublishDate>
          <Heading>{title}</Heading>

          {excerpt ? <Excerpt>{excerpt}</Excerpt> : null}

          <Button
            to={'/press-releases/' + slug}
            color={'secondary'}
            title={'Read more about ' + title}
          >
            Read More
          </Button>
        </div>
      </div>
    </ItemContainer>
  )
}

export default PressReleaseItem
